import React from 'react'
import LegalTermsofService from '../../src/components/LegalTermsOfService/index'
import { Container } from './styles'

const App = (props) => {
  return (
    <Container>
      <LegalTermsofService {...props} />
    </Container>
  )
}

export default App
