import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Container = styled.section<{
  direction?: string
  marginLeft?: string
}>`
  background: ${primitives.color.white};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: #363636;
  padding: ${primitives.layout.size8}px;
  font-family: ${primitives.typography.family0} !important;
  font-size: ${primitives.typography.size2} !important;
  margin-bottom: 100px;
  direction: ${({ direction }) => direction};
  h1 {
    font-family: ${primitives.typography.family0} !important;
    margin: 0;
    font-style: italic;
    font-size: ${primitives.typography.size5} !important;
    line-height: ${primitives.typography.lineHeight7} !important;
  }
  h4 {
    font-family: ${primitives.typography.family0} !important;
    margin: 0;
    font-style: italic;
    font-size: ${primitives.typography.size2} !important;
    line-height: ${primitives.typography.lineHeight1} !important;
  }
  h3 {
    font-family: ${primitives.typography.family0} !important;
    margin: ${primitives.layout.size4}px 0;
    font-size: ${primitives.typography.size3} !important;
    line-height: ${primitives.typography.lineHeight0} !important;
  }
  a {
    margin: 0 ${primitives.layout.size1}px;
    font-weight: 600;
    text-decoration: none;
    color: #027aae;
  }
  .italicText {
    font-style: italic;
  }
  ul {
    counter-reset: outItem;
    list-style: none;
    margin: 0;
    font-size: ${primitives.typography.size1} !important;
    padding: revert;
  }
  ul > li {
    margin: ${primitives.layout.size3}px 0;
    counter-reset: nestedItem;
  }
  ul > li:before {
    content: counters(outItem, '.') '. ';
    counter-increment: outItem;
    margin-left: ${({ marginLeft }) => marginLeft};
  }
`
export const Paragraph = styled.p`
  margin: ${primitives.layout.size4}px 0;
`
export const StrongText = styled.span`
  font-weight: 600;
  margin: 0 ${primitives.layout.size1}px;
`
export const CopyRightsFooter = styled.span`
  font-size: 14px;
`
export const AddressBlock = styled.div`
  margin: ${primitives.layout.size8}px;
`
export const LanguageSelector = styled.div`
  .label {
    font-family: ${primitives.typography.family0} !important;
  }
  width: 232px;
  border-radius: 0px;
`
export const FooterStyles = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  footer > div:first-child {
    background-color: #f8f8f8 !important;
    padding: 15px 20px !important;
  }
`
